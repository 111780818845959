//
// Automatically generated by mix operately.gen.page.index
// Do not edit this file directly
//

import * as AccountAppearancePage from "./AccountAppearancePage";
import * as AccountChangePasswordPage from "./AccountChangePasswordPage";
import * as AccountPage from "./AccountPage";
import * as AccountSecurityPage from "./AccountSecurityPage";
import * as CompanyAdminAddPeoplePage from "./CompanyAdminAddPeoplePage";
import * as CompanyAdminManageAdminsPage from "./CompanyAdminManageAdminsPage";
import * as CompanyAdminManagePeoplePage from "./CompanyAdminManagePeoplePage";
import * as CompanyAdminPage from "./CompanyAdminPage";
import * as CompanyAdminRestoreSuspendedPeoplePage from "./CompanyAdminRestoreSuspendedPeoplePage";
import * as CompanyAdminTrustedEmailDomainsPage from "./CompanyAdminTrustedEmailDomainsPage";
import * as CompanyPermissionsPage from "./CompanyPermissionsPage";
import * as CompanyRenamePage from "./CompanyRenamePage";
import * as DesignButtonsPage from "./DesignButtonsPage";
import * as DesignCalloutsPage from "./DesignCalloutsPage";
import * as DesignColorsPage from "./DesignColorsPage";
import * as DesignFormsPage from "./DesignFormsPage";
import * as DesignLinksPage from "./DesignLinksPage";
import * as DesignMenusPage from "./DesignMenusPage";
import * as DesignPage from "./DesignPage";
import * as DesignProgressPage from "./DesignProgressPage";
import * as DesignTooltipsPage from "./DesignTooltipsPage";
import * as DiscussionDraftsPage from "./DiscussionDraftsPage";
import * as DiscussionEditPage from "./DiscussionEditPage";
import * as DiscussionNewPage from "./DiscussionNewPage";
import * as DiscussionPage from "./DiscussionPage";
import * as FeedPage from "./FeedPage";
import * as ForgotPasswordPage from "./ForgotPasswordPage";
import * as GoalAboutPage from "./GoalAboutPage";
import * as GoalActivityPage from "./GoalActivityPage";
import * as GoalAddPage from "./GoalAddPage";
import * as GoalArchivePage from "./GoalArchivePage";
import * as GoalCheckInNewPage from "./GoalCheckInNewPage";
import * as GoalCheckInPage from "./GoalCheckInPage";
import * as GoalClosingPage from "./GoalClosingPage";
import * as GoalDiscussionEditPage from "./GoalDiscussionEditPage";
import * as GoalDiscussionNewPage from "./GoalDiscussionNewPage";
import * as GoalDiscussionsPage from "./GoalDiscussionsPage";
import * as GoalEditPage from "./GoalEditPage";
import * as GoalEditParentPage from "./GoalEditParentPage";
import * as GoalEditTimeframePage from "./GoalEditTimeframePage";
import * as GoalPage from "./GoalPage";
import * as GoalReopenPage from "./GoalReopenPage";
import * as GoalSubgoalsPage from "./GoalSubgoalsPage";
import * as GoalV2Page from "./GoalV2Page";
import * as GoalsAndProjectsPage from "./GoalsAndProjectsPage";
import * as HomePage from "./HomePage";
import * as JoinPage from "./JoinPage";
import * as LobbyPage from "./LobbyPage";
import * as LoginPage from "./LoginPage";
import * as NewCompanyPage from "./NewCompanyPage";
import * as NotFoundPage from "./NotFoundPage";
import * as NotificationsPage from "./NotificationsPage";
import * as PeopleOrgChartPage from "./PeopleOrgChartPage";
import * as PeoplePage from "./PeoplePage";
import * as ProfileEditPage from "./ProfileEditPage";
import * as ProfileGoalsPage from "./ProfileGoalsPage";
import * as ProfilePage from "./ProfilePage";
import * as ProjectAddPage from "./ProjectAddPage";
import * as ProjectAddResourcePage from "./ProjectAddResourcePage";
import * as ProjectArchivationPage from "./ProjectArchivationPage";
import * as ProjectCheckInEditPage from "./ProjectCheckInEditPage";
import * as ProjectCheckInNewPage from "./ProjectCheckInNewPage";
import * as ProjectCheckInPage from "./ProjectCheckInPage";
import * as ProjectCheckInsPage from "./ProjectCheckInsPage";
import * as ProjectClosePage from "./ProjectClosePage";
import * as ProjectContributorsAddPage from "./ProjectContributorsAddPage";
import * as ProjectContributorsEditPage from "./ProjectContributorsEditPage";
import * as ProjectContributorsPage from "./ProjectContributorsPage";
import * as ProjectEditAccessLevelsPage from "./ProjectEditAccessLevelsPage";
import * as ProjectEditDescriptionPage from "./ProjectEditDescriptionPage";
import * as ProjectEditGoalPage from "./ProjectEditGoalPage";
import * as ProjectEditProjectNamePage from "./ProjectEditProjectNamePage";
import * as ProjectEditResourcePage from "./ProjectEditResourcePage";
import * as ProjectEditResourcesPage from "./ProjectEditResourcesPage";
import * as ProjectEditTimelinePage from "./ProjectEditTimelinePage";
import * as ProjectMilestonePage from "./ProjectMilestonePage";
import * as ProjectMilestonesPage from "./ProjectMilestonesPage";
import * as ProjectMovePage from "./ProjectMovePage";
import * as ProjectPage from "./ProjectPage";
import * as ProjectPausePage from "./ProjectPausePage";
import * as ProjectResumePage from "./ProjectResumePage";
import * as ProjectRetrospectiveEditPage from "./ProjectRetrospectiveEditPage";
import * as ProjectRetrospectivePage from "./ProjectRetrospectivePage";
import * as ProjectsPage from "./ProjectsPage";
import * as RadarPage from "./RadarPage";
import * as ResetPasswordPage from "./ResetPasswordPage";
import * as ResourceHubDocumentPage from "./ResourceHubDocumentPage";
import * as ResourceHubDraftsPage from "./ResourceHubDraftsPage";
import * as ResourceHubEditDocumentPage from "./ResourceHubEditDocumentPage";
import * as ResourceHubEditFilePage from "./ResourceHubEditFilePage";
import * as ResourceHubEditLinkPage from "./ResourceHubEditLinkPage";
import * as ResourceHubFilePage from "./ResourceHubFilePage";
import * as ResourceHubFolderPage from "./ResourceHubFolderPage";
import * as ResourceHubLinkPage from "./ResourceHubLinkPage";
import * as ResourceHubNewDocumentPage from "./ResourceHubNewDocumentPage";
import * as ResourceHubNewLinkPage from "./ResourceHubNewLinkPage";
import * as ResourceHubPage from "./ResourceHubPage";
import * as ReviewPage from "./ReviewPage";
import * as SetupPage from "./SetupPage";
import * as SignUpPage from "./SignUpPage";
import * as SignUpWithEmailPage from "./SignUpWithEmailPage";
import * as SpaceAccessManagementPage from "./SpaceAccessManagementPage";
import * as SpaceAddMembersPage from "./SpaceAddMembersPage";
import * as SpaceAddPage from "./SpaceAddPage";
import * as SpaceDiscussionsPage from "./SpaceDiscussionsPage";
import * as SpaceEditGeneralAccessPage from "./SpaceEditGeneralAccessPage";
import * as SpaceEditPage from "./SpaceEditPage";
import * as SpaceGoalsPage from "./SpaceGoalsPage";
import * as SpacePage from "./SpacePage";
import * as TaskPage from "./TaskPage";

//
// We are explocily exporting the pages here to avoid false positives
// dead code repors form knip
//
export default {
  AccountAppearancePage: {
    name: "AccountAppearancePage",
    loader: AccountAppearancePage.loader,
    Page: AccountAppearancePage.Page,
  },
  AccountChangePasswordPage: {
    name: "AccountChangePasswordPage",
    loader: AccountChangePasswordPage.loader,
    Page: AccountChangePasswordPage.Page,
  },
  AccountPage: {
    name: "AccountPage",
    loader: AccountPage.loader,
    Page: AccountPage.Page,
  },
  AccountSecurityPage: {
    name: "AccountSecurityPage",
    loader: AccountSecurityPage.loader,
    Page: AccountSecurityPage.Page,
  },
  CompanyAdminAddPeoplePage: {
    name: "CompanyAdminAddPeoplePage",
    loader: CompanyAdminAddPeoplePage.loader,
    Page: CompanyAdminAddPeoplePage.Page,
  },
  CompanyAdminManageAdminsPage: {
    name: "CompanyAdminManageAdminsPage",
    loader: CompanyAdminManageAdminsPage.loader,
    Page: CompanyAdminManageAdminsPage.Page,
  },
  CompanyAdminManagePeoplePage: {
    name: "CompanyAdminManagePeoplePage",
    loader: CompanyAdminManagePeoplePage.loader,
    Page: CompanyAdminManagePeoplePage.Page,
  },
  CompanyAdminPage: {
    name: "CompanyAdminPage",
    loader: CompanyAdminPage.loader,
    Page: CompanyAdminPage.Page,
  },
  CompanyAdminRestoreSuspendedPeoplePage: {
    name: "CompanyAdminRestoreSuspendedPeoplePage",
    loader: CompanyAdminRestoreSuspendedPeoplePage.loader,
    Page: CompanyAdminRestoreSuspendedPeoplePage.Page,
  },
  CompanyAdminTrustedEmailDomainsPage: {
    name: "CompanyAdminTrustedEmailDomainsPage",
    loader: CompanyAdminTrustedEmailDomainsPage.loader,
    Page: CompanyAdminTrustedEmailDomainsPage.Page,
  },
  CompanyPermissionsPage: {
    name: "CompanyPermissionsPage",
    loader: CompanyPermissionsPage.loader,
    Page: CompanyPermissionsPage.Page,
  },
  CompanyRenamePage: {
    name: "CompanyRenamePage",
    loader: CompanyRenamePage.loader,
    Page: CompanyRenamePage.Page,
  },
  DesignButtonsPage: {
    name: "DesignButtonsPage",
    loader: DesignButtonsPage.loader,
    Page: DesignButtonsPage.Page,
  },
  DesignCalloutsPage: {
    name: "DesignCalloutsPage",
    loader: DesignCalloutsPage.loader,
    Page: DesignCalloutsPage.Page,
  },
  DesignColorsPage: {
    name: "DesignColorsPage",
    loader: DesignColorsPage.loader,
    Page: DesignColorsPage.Page,
  },
  DesignFormsPage: {
    name: "DesignFormsPage",
    loader: DesignFormsPage.loader,
    Page: DesignFormsPage.Page,
  },
  DesignLinksPage: {
    name: "DesignLinksPage",
    loader: DesignLinksPage.loader,
    Page: DesignLinksPage.Page,
  },
  DesignMenusPage: {
    name: "DesignMenusPage",
    loader: DesignMenusPage.loader,
    Page: DesignMenusPage.Page,
  },
  DesignPage: {
    name: "DesignPage",
    loader: DesignPage.loader,
    Page: DesignPage.Page,
  },
  DesignProgressPage: {
    name: "DesignProgressPage",
    loader: DesignProgressPage.loader,
    Page: DesignProgressPage.Page,
  },
  DesignTooltipsPage: {
    name: "DesignTooltipsPage",
    loader: DesignTooltipsPage.loader,
    Page: DesignTooltipsPage.Page,
  },
  DiscussionDraftsPage: {
    name: "DiscussionDraftsPage",
    loader: DiscussionDraftsPage.loader,
    Page: DiscussionDraftsPage.Page,
  },
  DiscussionEditPage: {
    name: "DiscussionEditPage",
    loader: DiscussionEditPage.loader,
    Page: DiscussionEditPage.Page,
  },
  DiscussionNewPage: {
    name: "DiscussionNewPage",
    loader: DiscussionNewPage.loader,
    Page: DiscussionNewPage.Page,
  },
  DiscussionPage: {
    name: "DiscussionPage",
    loader: DiscussionPage.loader,
    Page: DiscussionPage.Page,
  },
  FeedPage: {
    name: "FeedPage",
    loader: FeedPage.loader,
    Page: FeedPage.Page,
  },
  ForgotPasswordPage: {
    name: "ForgotPasswordPage",
    loader: ForgotPasswordPage.loader,
    Page: ForgotPasswordPage.Page,
  },
  GoalAboutPage: {
    name: "GoalAboutPage",
    loader: GoalAboutPage.loader,
    Page: GoalAboutPage.Page,
  },
  GoalActivityPage: {
    name: "GoalActivityPage",
    loader: GoalActivityPage.loader,
    Page: GoalActivityPage.Page,
  },
  GoalAddPage: {
    name: "GoalAddPage",
    loader: GoalAddPage.loader,
    Page: GoalAddPage.Page,
  },
  GoalArchivePage: {
    name: "GoalArchivePage",
    loader: GoalArchivePage.loader,
    Page: GoalArchivePage.Page,
  },
  GoalCheckInNewPage: {
    name: "GoalCheckInNewPage",
    loader: GoalCheckInNewPage.loader,
    Page: GoalCheckInNewPage.Page,
  },
  GoalCheckInPage: {
    name: "GoalCheckInPage",
    loader: GoalCheckInPage.loader,
    Page: GoalCheckInPage.Page,
  },
  GoalClosingPage: {
    name: "GoalClosingPage",
    loader: GoalClosingPage.loader,
    Page: GoalClosingPage.Page,
  },
  GoalDiscussionEditPage: {
    name: "GoalDiscussionEditPage",
    loader: GoalDiscussionEditPage.loader,
    Page: GoalDiscussionEditPage.Page,
  },
  GoalDiscussionNewPage: {
    name: "GoalDiscussionNewPage",
    loader: GoalDiscussionNewPage.loader,
    Page: GoalDiscussionNewPage.Page,
  },
  GoalDiscussionsPage: {
    name: "GoalDiscussionsPage",
    loader: GoalDiscussionsPage.loader,
    Page: GoalDiscussionsPage.Page,
  },
  GoalEditPage: {
    name: "GoalEditPage",
    loader: GoalEditPage.loader,
    Page: GoalEditPage.Page,
  },
  GoalEditParentPage: {
    name: "GoalEditParentPage",
    loader: GoalEditParentPage.loader,
    Page: GoalEditParentPage.Page,
  },
  GoalEditTimeframePage: {
    name: "GoalEditTimeframePage",
    loader: GoalEditTimeframePage.loader,
    Page: GoalEditTimeframePage.Page,
  },
  GoalPage: {
    name: "GoalPage",
    loader: GoalPage.loader,
    Page: GoalPage.Page,
  },
  GoalReopenPage: {
    name: "GoalReopenPage",
    loader: GoalReopenPage.loader,
    Page: GoalReopenPage.Page,
  },
  GoalSubgoalsPage: {
    name: "GoalSubgoalsPage",
    loader: GoalSubgoalsPage.loader,
    Page: GoalSubgoalsPage.Page,
  },
  GoalV2Page: {
    name: "GoalV2Page",
    loader: GoalV2Page.loader,
    Page: GoalV2Page.Page,
  },
  GoalsAndProjectsPage: {
    name: "GoalsAndProjectsPage",
    loader: GoalsAndProjectsPage.loader,
    Page: GoalsAndProjectsPage.Page,
  },
  HomePage: {
    name: "HomePage",
    loader: HomePage.loader,
    Page: HomePage.Page,
  },
  JoinPage: {
    name: "JoinPage",
    loader: JoinPage.loader,
    Page: JoinPage.Page,
  },
  LobbyPage: {
    name: "LobbyPage",
    loader: LobbyPage.loader,
    Page: LobbyPage.Page,
  },
  LoginPage: {
    name: "LoginPage",
    loader: LoginPage.loader,
    Page: LoginPage.Page,
  },
  NewCompanyPage: {
    name: "NewCompanyPage",
    loader: NewCompanyPage.loader,
    Page: NewCompanyPage.Page,
  },
  NotFoundPage: {
    name: "NotFoundPage",
    loader: NotFoundPage.loader,
    Page: NotFoundPage.Page,
  },
  NotificationsPage: {
    name: "NotificationsPage",
    loader: NotificationsPage.loader,
    Page: NotificationsPage.Page,
  },
  PeopleOrgChartPage: {
    name: "PeopleOrgChartPage",
    loader: PeopleOrgChartPage.loader,
    Page: PeopleOrgChartPage.Page,
  },
  PeoplePage: {
    name: "PeoplePage",
    loader: PeoplePage.loader,
    Page: PeoplePage.Page,
  },
  ProfileEditPage: {
    name: "ProfileEditPage",
    loader: ProfileEditPage.loader,
    Page: ProfileEditPage.Page,
  },
  ProfileGoalsPage: {
    name: "ProfileGoalsPage",
    loader: ProfileGoalsPage.loader,
    Page: ProfileGoalsPage.Page,
  },
  ProfilePage: {
    name: "ProfilePage",
    loader: ProfilePage.loader,
    Page: ProfilePage.Page,
  },
  ProjectAddPage: {
    name: "ProjectAddPage",
    loader: ProjectAddPage.loader,
    Page: ProjectAddPage.Page,
  },
  ProjectAddResourcePage: {
    name: "ProjectAddResourcePage",
    loader: ProjectAddResourcePage.loader,
    Page: ProjectAddResourcePage.Page,
  },
  ProjectArchivationPage: {
    name: "ProjectArchivationPage",
    loader: ProjectArchivationPage.loader,
    Page: ProjectArchivationPage.Page,
  },
  ProjectCheckInEditPage: {
    name: "ProjectCheckInEditPage",
    loader: ProjectCheckInEditPage.loader,
    Page: ProjectCheckInEditPage.Page,
  },
  ProjectCheckInNewPage: {
    name: "ProjectCheckInNewPage",
    loader: ProjectCheckInNewPage.loader,
    Page: ProjectCheckInNewPage.Page,
  },
  ProjectCheckInPage: {
    name: "ProjectCheckInPage",
    loader: ProjectCheckInPage.loader,
    Page: ProjectCheckInPage.Page,
  },
  ProjectCheckInsPage: {
    name: "ProjectCheckInsPage",
    loader: ProjectCheckInsPage.loader,
    Page: ProjectCheckInsPage.Page,
  },
  ProjectClosePage: {
    name: "ProjectClosePage",
    loader: ProjectClosePage.loader,
    Page: ProjectClosePage.Page,
  },
  ProjectContributorsAddPage: {
    name: "ProjectContributorsAddPage",
    loader: ProjectContributorsAddPage.loader,
    Page: ProjectContributorsAddPage.Page,
  },
  ProjectContributorsEditPage: {
    name: "ProjectContributorsEditPage",
    loader: ProjectContributorsEditPage.loader,
    Page: ProjectContributorsEditPage.Page,
  },
  ProjectContributorsPage: {
    name: "ProjectContributorsPage",
    loader: ProjectContributorsPage.loader,
    Page: ProjectContributorsPage.Page,
  },
  ProjectEditAccessLevelsPage: {
    name: "ProjectEditAccessLevelsPage",
    loader: ProjectEditAccessLevelsPage.loader,
    Page: ProjectEditAccessLevelsPage.Page,
  },
  ProjectEditDescriptionPage: {
    name: "ProjectEditDescriptionPage",
    loader: ProjectEditDescriptionPage.loader,
    Page: ProjectEditDescriptionPage.Page,
  },
  ProjectEditGoalPage: {
    name: "ProjectEditGoalPage",
    loader: ProjectEditGoalPage.loader,
    Page: ProjectEditGoalPage.Page,
  },
  ProjectEditProjectNamePage: {
    name: "ProjectEditProjectNamePage",
    loader: ProjectEditProjectNamePage.loader,
    Page: ProjectEditProjectNamePage.Page,
  },
  ProjectEditResourcePage: {
    name: "ProjectEditResourcePage",
    loader: ProjectEditResourcePage.loader,
    Page: ProjectEditResourcePage.Page,
  },
  ProjectEditResourcesPage: {
    name: "ProjectEditResourcesPage",
    loader: ProjectEditResourcesPage.loader,
    Page: ProjectEditResourcesPage.Page,
  },
  ProjectEditTimelinePage: {
    name: "ProjectEditTimelinePage",
    loader: ProjectEditTimelinePage.loader,
    Page: ProjectEditTimelinePage.Page,
  },
  ProjectMilestonePage: {
    name: "ProjectMilestonePage",
    loader: ProjectMilestonePage.loader,
    Page: ProjectMilestonePage.Page,
  },
  ProjectMilestonesPage: {
    name: "ProjectMilestonesPage",
    loader: ProjectMilestonesPage.loader,
    Page: ProjectMilestonesPage.Page,
  },
  ProjectMovePage: {
    name: "ProjectMovePage",
    loader: ProjectMovePage.loader,
    Page: ProjectMovePage.Page,
  },
  ProjectPage: {
    name: "ProjectPage",
    loader: ProjectPage.loader,
    Page: ProjectPage.Page,
  },
  ProjectPausePage: {
    name: "ProjectPausePage",
    loader: ProjectPausePage.loader,
    Page: ProjectPausePage.Page,
  },
  ProjectResumePage: {
    name: "ProjectResumePage",
    loader: ProjectResumePage.loader,
    Page: ProjectResumePage.Page,
  },
  ProjectRetrospectiveEditPage: {
    name: "ProjectRetrospectiveEditPage",
    loader: ProjectRetrospectiveEditPage.loader,
    Page: ProjectRetrospectiveEditPage.Page,
  },
  ProjectRetrospectivePage: {
    name: "ProjectRetrospectivePage",
    loader: ProjectRetrospectivePage.loader,
    Page: ProjectRetrospectivePage.Page,
  },
  ProjectsPage: {
    name: "ProjectsPage",
    loader: ProjectsPage.loader,
    Page: ProjectsPage.Page,
  },
  RadarPage: {
    name: "RadarPage",
    loader: RadarPage.loader,
    Page: RadarPage.Page,
  },
  ResetPasswordPage: {
    name: "ResetPasswordPage",
    loader: ResetPasswordPage.loader,
    Page: ResetPasswordPage.Page,
  },
  ResourceHubDocumentPage: {
    name: "ResourceHubDocumentPage",
    loader: ResourceHubDocumentPage.loader,
    Page: ResourceHubDocumentPage.Page,
  },
  ResourceHubDraftsPage: {
    name: "ResourceHubDraftsPage",
    loader: ResourceHubDraftsPage.loader,
    Page: ResourceHubDraftsPage.Page,
  },
  ResourceHubEditDocumentPage: {
    name: "ResourceHubEditDocumentPage",
    loader: ResourceHubEditDocumentPage.loader,
    Page: ResourceHubEditDocumentPage.Page,
  },
  ResourceHubEditFilePage: {
    name: "ResourceHubEditFilePage",
    loader: ResourceHubEditFilePage.loader,
    Page: ResourceHubEditFilePage.Page,
  },
  ResourceHubEditLinkPage: {
    name: "ResourceHubEditLinkPage",
    loader: ResourceHubEditLinkPage.loader,
    Page: ResourceHubEditLinkPage.Page,
  },
  ResourceHubFilePage: {
    name: "ResourceHubFilePage",
    loader: ResourceHubFilePage.loader,
    Page: ResourceHubFilePage.Page,
  },
  ResourceHubFolderPage: {
    name: "ResourceHubFolderPage",
    loader: ResourceHubFolderPage.loader,
    Page: ResourceHubFolderPage.Page,
  },
  ResourceHubLinkPage: {
    name: "ResourceHubLinkPage",
    loader: ResourceHubLinkPage.loader,
    Page: ResourceHubLinkPage.Page,
  },
  ResourceHubNewDocumentPage: {
    name: "ResourceHubNewDocumentPage",
    loader: ResourceHubNewDocumentPage.loader,
    Page: ResourceHubNewDocumentPage.Page,
  },
  ResourceHubNewLinkPage: {
    name: "ResourceHubNewLinkPage",
    loader: ResourceHubNewLinkPage.loader,
    Page: ResourceHubNewLinkPage.Page,
  },
  ResourceHubPage: {
    name: "ResourceHubPage",
    loader: ResourceHubPage.loader,
    Page: ResourceHubPage.Page,
  },
  ReviewPage: {
    name: "ReviewPage",
    loader: ReviewPage.loader,
    Page: ReviewPage.Page,
  },
  SetupPage: {
    name: "SetupPage",
    loader: SetupPage.loader,
    Page: SetupPage.Page,
  },
  SignUpPage: {
    name: "SignUpPage",
    loader: SignUpPage.loader,
    Page: SignUpPage.Page,
  },
  SignUpWithEmailPage: {
    name: "SignUpWithEmailPage",
    loader: SignUpWithEmailPage.loader,
    Page: SignUpWithEmailPage.Page,
  },
  SpaceAccessManagementPage: {
    name: "SpaceAccessManagementPage",
    loader: SpaceAccessManagementPage.loader,
    Page: SpaceAccessManagementPage.Page,
  },
  SpaceAddMembersPage: {
    name: "SpaceAddMembersPage",
    loader: SpaceAddMembersPage.loader,
    Page: SpaceAddMembersPage.Page,
  },
  SpaceAddPage: {
    name: "SpaceAddPage",
    loader: SpaceAddPage.loader,
    Page: SpaceAddPage.Page,
  },
  SpaceDiscussionsPage: {
    name: "SpaceDiscussionsPage",
    loader: SpaceDiscussionsPage.loader,
    Page: SpaceDiscussionsPage.Page,
  },
  SpaceEditGeneralAccessPage: {
    name: "SpaceEditGeneralAccessPage",
    loader: SpaceEditGeneralAccessPage.loader,
    Page: SpaceEditGeneralAccessPage.Page,
  },
  SpaceEditPage: {
    name: "SpaceEditPage",
    loader: SpaceEditPage.loader,
    Page: SpaceEditPage.Page,
  },
  SpaceGoalsPage: {
    name: "SpaceGoalsPage",
    loader: SpaceGoalsPage.loader,
    Page: SpaceGoalsPage.Page,
  },
  SpacePage: {
    name: "SpacePage",
    loader: SpacePage.loader,
    Page: SpacePage.Page,
  },
  TaskPage: {
    name: "TaskPage",
    loader: TaskPage.loader,
    Page: TaskPage.Page,
  },
};
